export default function NotFound() {
    return (
        <div className="flex flex-col space-y-8 mx-4 justify-center items-center min-h-screen retro">
            <h1 className="text-5xl">404 - Page Not Found</h1>
            <a href="/" className="text-3xl">
                Go to the homepage
            </a>
        </div>
    );
}
